<script lang="ts" setup>
const isTablet = useMediaQuery(TABLET_AND_UP);
const { appImageBasePath, tenantKey } = useBranding();

const title = computed(() => nxtPeopleLayoutProps.value?.title);
const bannerUrl = computed(() => nxtPeopleLayoutProps.value?.bannerUrl);
const overlayColor = computed(() => nxtPeopleLayoutProps.value?.overlayColor);
const tenantLogoUrl = computed(() => nxtPeopleLayoutProps.value?.tenantLogoUrl);
</script>

<template>
   <div :class="'nxt-people-page'">
      <LayoutNxtPeopleHeader
         :class="'nxt-people-page__header'"
         :tenant-logo-url="tenantLogoUrl"
         :banner-url="bannerUrl"
         :overlay-color="overlayColor"
      />
      <FormLanguageSwitcher :mode="isTablet ? 'dark' : 'light'" :class="'nxt-people-page__language'" />

      <main class="nxt-people-page__main">
         <div class="nxt-people-page__content">
            <img class="nxt-people-page__logo" :src="tenantLogoUrl ?? `/${appImageBasePath}/logo-dark.png`" :height="50" />

            <h1 class="nxt-people-page__title">{{ title }}</h1>
            <slot></slot>
         </div>

         <footer class="nxt-people-page__footer">
            <a v-if="tenantKey !== 'nxt-plan'" href="https://nxtpeople.com" target="_blank" alt="NxtPeople">
               <NuxtImg :src="'/images/powered-by.svg'" :height="50" :quality="100" />
            </a>
         </footer>
      </main>
   </div>
</template>

<style lang="scss" scoped>
.nxt-people-page {
   background-color: var(--nxt-white);
   position: relative;
   display: flex;
   flex-direction: column;
   height: 100%;
   border-top: env(safe-area-inset-top, 0) solid var(--nxt-main);

   @include tablet {
      flex-direction: row;
   }
}

.nxt-people-page__language {
   position: absolute;
   top: var(--nxt-gutter);
   right: var(--nxt-gutter);
   z-index: var(--zindex-dropdown);
}

.nxt-people-page__main {
   z-index: 1;
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);

   background-color: var(--nxt-white);
   margin-top: var(--nxt-gutter-large--negative);
   border-radius: var(--nxt-radius);

   height: 100%;
   overflow: auto;

   @include tablet {
      min-width: toRem(500);
      margin: unset;
      padding: var(--nxt-gutter);
      justify-content: space-between;
   }
}

.nxt-people-page__title {
   text-align: center;
}

.nxt-people-page__content {
   flex: 1;
   display: flex;
   flex-direction: column;

   @include tablet {
      flex: unset;
      margin-top: toRem(80);
   }
}

.nxt-people-page__logo {
   display: none;

   @include tablet {
      margin: 0 auto;
      display: block;
      object-fit: contain;

      width: 100%;
      height: auto;
      max-height: toRem(120);
   }
}

.nxt-people-page__footer {
   display: flex;
   justify-content: center;
   align-items: center;
}
</style>
