<script lang="ts" setup>
import { vOnClickOutside } from '@vueuse/components';

// #region Props & Emits
defineProps({
   mode: { type: String as PropType<'light' | 'dark'>, default: 'dark' },
});
// #endregion

// #region Languages
const { data: communicationLanguagesResponse } = usePublicFetch<ApiResponse<Array<CommunicationLanguage>>>(
   COMMUNICATION_LANGUAGE_ENDPOINTS.GET(),
   { ...cacheConfig },
);

const communicationLanguages = computed(() =>
   (communicationLanguagesResponse.value?.data ?? []).map((language) => ({ ...language, iso_639_1: language.iso_639_1.toLowerCase() })),
);
// #endregion

// #region Open state
const isOpen = ref(false);

function close() {
   isOpen.value = false;
}
// #endregion

// #region Switch language
const { locale, setLocale } = useI18n();

function changeLocale(locale: string) {
   setLocale(locale);
   clearCache();
   close();
}
// #endregion
</script>

<template>
   <div v-on-click-outside="close" :class="'language-switcher'" @keydown.escape="isOpen = false">
      <button type="button" :class="['language-switcher__toggle', { open: isOpen }, mode]" @click.stop="isOpen = !isOpen">
         <span>{{ locale }}</span>
         <i class="fa-solid fa-chevron-down"></i>
      </button>

      <ul v-if="isOpen" class="language-switcher__list">
         <li v-for="language in communicationLanguages" :key="language.iso_639_1" class="language-switcher__list__item">
            <button
               type="button"
               :class="{ 'language-switcher__item--active': language.iso_639_1 === locale }"
               @click="changeLocale(language.iso_639_1)"
            >
               {{ language.name }}
            </button>
         </li>
      </ul>
   </div>
</template>

<style lang="scss" scoped>
.language-switcher {
   position: relative;
}

.language-switcher__toggle {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: var(--nxt-gutter);
   padding: var(--nxt-gutter-small);
   text-transform: uppercase;
   cursor: pointer;
   background-color: transparent;
   border: none;

   i {
      transition: transform 0.3s ease-in-out;
   }

   &.open {
      i {
         transform: rotate(180deg);
      }
   }

   &.light {
      color: var(--nxt-white);
   }
}

.language-switcher__list {
   position: absolute;
   top: 100%;
   right: 0;
   background-color: var(--nxt-white);
   border-radius: var(--nxt-radius-large);
   box-shadow: var(--nxt-shadow);
   padding: var(--nxt-gutter);
   list-style: none;
   margin: 0;
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
   z-index: var(--zindex-dropdown);
}

.language-switcher__list__item {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: var(--nxt-gutter);

   button {
      width: 100%;
      background-color: transparent;
      border: none;
      padding: var(--nxt-gutter-small);
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;

      &:hover {
         background-color: var(--nxt-main-highlight);
      }

      &--active {
         background-color: var(--nxt-main);
         color: var(--nxt-white);
      }
   }
}
</style>
