<script lang="ts" setup>
const { appImageBasePath } = useBranding();

const props = withDefaults(defineProps<{ overlayColor?: string; bannerUrl: string; tenantLogoUrl?: string }>(), {
   overlayColor: 'var(--nxt-main-overlay)',
   bannerUrl: 'https://nxtpeople-cdn.s3.eu-central-1.amazonaws.com/keycloak/keycloak-banner-video.mp4',
});

const isImage = computed(
   () =>
      props.bannerUrl.endsWith('.png') ||
      props.bannerUrl.endsWith('.jpg') ||
      props.bannerUrl.endsWith('.jpeg') ||
      props.bannerUrl.endsWith('.svg'),
);
</script>

<template>
   <header :class="'nxt-people-header'">
      <div class="nxt-people-header__banner">
         <img v-if="isImage" class="nxt-people-header__image" :src="bannerUrl" />

         <video v-else class="nxt-people-header__video" playsinline autoplay loop muted preload="auto">
            <source :src="bannerUrl" />
         </video>
      </div>

      <img class="nxt-people-header__logo" :src="tenantLogoUrl ?? `/${appImageBasePath}/logo-light.png`" :height="30" />
   </header>
</template>

<style lang="scss" scoped>
.nxt-people-header {
   position: relative;
   height: toRem(180);

   @include tablet {
      max-height: unset;
      height: unset;
   }
}

.nxt-people-header__banner {
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
   height: 100%;

   &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: v-bind(overlayColor);
   }
}

.nxt-people-header__video {
   height: 100%;
   width: 100%;
   overflow: hidden;
   object-fit: cover;
}

.nxt-people-header__image {
   height: 100%;
   width: 100%;
   object-fit: cover;
}

.nxt-people-header__logo {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   z-index: 1;
   max-width: 50vw;
   height: auto;
   max-height: toRem(80);
   margin-top: toRem(-10);

   @include tablet {
      display: none;
   }
}
</style>
